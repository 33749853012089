@import url('https://fonts.googleapis.com/css?family=Merriweather|Poppins|Raleway|Roboto+Slab|Roboto|Ubuntu|Montserrat&display=swap');
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import 'variables';
@import 'global';
@import 'diff2html/bundles/css/diff2html.min.css';

// TODO: Move fonts to @certemy/theme

@font-face {
    font-family: 'Noto Sans';
    src: url('/assets/fonts/NotoSans-Regular-webfont.eot');
    src: url('/assets/fonts/NotoSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/NotoSans-Regular-webfont.woff') format('woff'),
        url('/assets/fonts/NotoSans-Regular-webfont.ttf') format('truetype'),
        url('/assets/fonts/NotoSans-Regular-webfont.svg#noto_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Noto Sans';
    src: url('/assets/fonts/NotoSans-Bold-webfont.eot');
    src: url('/assets/fonts/NotoSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/NotoSans-Bold-webfont.woff') format('woff'),
        url('/assets/fonts/NotoSans-Bold-webfont.ttf') format('truetype'),
        url('/assets/fonts/NotoSans-Bold-webfont.svg#noto_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

body {
    /*overflow-x: hidden;*/
    font-family: $font-base;
    font-weight: lighter;
    background-color: $color-grey-fafa;
}

html,
body {
    height: 100%;
    width: 100%;
}

a {
    cursor: pointer;
}

.panel {
    background-color: #eeeeee;
}

.d2h-code-line-ctn {
    white-space: normal;
}
.d2h-code-line,
.d2h-code-side-line,
.d2h-code-line-ctn {
    width: revert;
}
.d2h-file-list-wrapper.d2h-light-color-scheme {
    display: none;
}

.modal-backdrop {
    z-index: 1050 !important;
}

.visually-hidden {
   display: none;
}
