@import 'certemy-shared/projects/theme/styles/variables';

:root {
    --baseColor: #5cd1d8;
    --baseHoverColor: #4ec6cc;
    --baseActiveColor: #39b9bf;
    --menuBase: #003033;
    --menuButton: #014c50;
    --menuButtonHover: #027c82;
    --baseFont: 'Noto sans';
    --headingFont: 'Arial';
}

/**
  Commented variables are placed at certemy-shared project
  You can uncomment variable to redefine with app specific value
 */

// $font-family-noto: 'Noto Sans', sans-serif;
// $font-family-merriweather: 'Merriweather', serif;
// $font-family-awesome: FontAwesome;

/* colors */
// $color-light-blue: #5cd1d8;
// $color-blue: #588fdf;
// $color-green: #50da9d;
// $color-red: #d8635c;
// $color-orange: #ff8533;
// $color-dark-blue: #3179a3;
// $color-disabled: #aaa;

// $color-text-primary: #222;
// $color-text-secondary: #777;

// $border-color: #eee;
// $placeholder-color: #aaa;

// $form-control-border-color: #ced4da;

// $break-point-mobile: 700px;
// $break-point-tablet: 900px;
// $break-point-desktop: 1200px;

$header-height: 77px;
// $breadcrumbs-height: 40px;

// $btn-height: 34px;
// $btn-padding: 5px 25px;
// $btn-padding-sm: 5px 10px;

// Default bootstrap modal sizes
// $modal-lg: 800px !default;
// $modal-md: 500px !default;
// $modal-sm: 300px !default;

// $active-item-bg-color: rgba(83, 142, 214, 0.05);

$color-text-primary: #222;
$color-text-secondary: #777;

$color-base: var(--baseColor);
$color-hover-base: var(--baseHoverColor);
$color-active-base: var(--baseActiveColor);
$color-menu-base: var(--menuBase);
$color-menu-button: var(--menuButton);
$color-menu-button-hover: var(--menuButtonHover);

$font-base: var(--baseFont);
$font-heading: var(--headingFont);

$color-white: #fff;
$color-organization-blue: #337ab7;
$color-dark-blue: #053e4f;
$color-blue: #588fdf;
$color-blue-link-hover: #75acf0;
$certification-nav-border-color: #4f8bd3;
$color-dark-red: #8c1812;
$color-soft-red: #d51950;
$color-soft-green: #34a853;

$color-light-grey: #f2f2f2;
$color-soft-grey: #f7f7f7;
$color-lighter-grey: #eee;
$color-grey: #e4e4e4;
$color-darker-grey: #777;
$color-dark-grey: #333;
$light-gray-color: #999999;

$color-grey-fafa: #fafafa;
$color-grey-eaea: #eaeaea;
$color-grey-aaa: #aaa;
$color-menu-blue: #49c6cc;

$custom-field-bg-color: #f7fafd;

$color-text-black: $color-text-primary;
$color-text-grey: $color-text-secondary;
$color-text-warning: $color-red;
$color-template-dark-grey: #4a5454;

$step-details-letter-spacing: -0.3px;

$complete-step-title-font-size: 12px;
$complete-step-title-font-weight: 600;

$line-height: 1.43;
$letter-spacing: -0.3px;

$complete-step-container-top-spacing: 18px;
// loader variables
$loader-color: $color-white;
$loader-bg-color: rgba(0, 0, 0, 0.4);

$page-padding-bottom: 15px;

$wizard-height: 45px;

$min-card-page-height: calc(100vh - #{$header-height} - #{$page-padding-bottom} - #{$breadcrumbs-height});

@mixin form-control-sm() {
    .form-control {
        font-size: 12px;
        height: 28px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

@mixin is-invalid-input {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
