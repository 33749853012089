@import 'font-awesome/css/font-awesome.css';
@import 'variables';
@import './app/style/forms/forms';
@import 'app/style/drag-and-drop/drag-and-drop';
@import './app/features/professional-step/professional-step.general';
@import './app/style/mixins/mixins.scss';
@import 'certemy-shared/projects/theme/styles/buttons';
@import 'certemy-shared/projects/theme/styles/modal';

.popover {
    max-width: 100% !important;
}

.field-cell {
    &__accessible,
    &__group,
    &__module,
    &__task,
    &__queue,
    &__settings {
        &__tooltip {
            .tooltip-inner {
                max-width: 620px !important;
            }
        }
    }
}

.user-list-smart-filters {
    padding-bottom: 200px;
}

// cm-groups-permissions {
//     cm-permission-tasks {
//         .field-headers {
//             .fields-header {
//                 &__check {
//                     min-width: 105px;
//                 }
//             }
//         }
//         .field-cell {
//             &__check {
//                 min-width: 95px;
//             }
//         }
//     }
// }

// #groups-permissions {
//     input:disabled + .cm-checkbox-label:before {
//         background-color: #a0a0a0;
//     }
// }

.panel {
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    &-body {
        padding: 15px;
    }
}

html {
    font-size: 16px;
}

body {
    overflow-x: hidden;
    color: $color-text-primary;
}

.modal-footer {
    background: $color-grey-fafa;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .ch- {
        &link {
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;
            text-decoration: none;
            background-color: transparent;
        }
        &button {
            font-weight: bold;
            font-size: 13px;
            padding: 7px 20px;
            text-transform: uppercase;
        }
    }
}

.modal-footer {
    flex-wrap: nowrap;
    > * { margin: 0; }
    > :not(:first-child) { margin-left: .25rem; }
    > :not(:last-child) { margin-right: .25rem; }
}

.custom-pagination {
    .pagination {
        margin: 0;
        .page-item {
            &.active {
                a {
                    border: 1px solid #4880ca;
                    color: #ffffff;
                    background-image: linear-gradient(to bottom, #5a91db, #4880ca);
                }
            }
            .page-link {
                padding: 5px 15px;
                font-size: 14px;
                letter-spacing: -0.3px;
                color: #222222;
            }
        }
    }
}

.line-limit-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-height: 50px;
}

.grey-text {
    color: #777777;
}

.mobile-safe-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: $break-point-mobile) {
        display: inline-block;
        max-width: 200px;
    }
}

.step-detail-title {
    color: $color-darker-grey;
    font-size: $complete-step-title-font-size;
    font-weight: $complete-step-title-font-weight;
    border-bottom: 1px solid $color-lighter-grey;
    letter-spacing: -0.3px;
    padding-bottom: 7px;
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

// Fixes for bootstrap 3 modals
.modal.fade.in .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, 0);
}

.modal-header {
    display: block;
}

.input-field {
    width: 100%;
    position: relative;
    background: transparent;

    .placeholder {
        position: absolute;
        margin: 0;
        font-weight: normal;
        color: #999;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 12px;
        height: 28px;
        padding: 3px 12px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;

        &-down-arrow {
            color: black;
        }
    }

    input {
        position: relative;
        opacity: 1;
        background: white;

        &.show-placeholder {
            opacity: 0.2;
            background: white;
        }

        &:focus {
            opacity: 1;
            background: white;
        }
    }
}

.supervisor-button-wrapper {
    .ch-button {
        padding: 7px 32px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.3px;
    }
    .cm-link {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.3px;
        text-align: center;
        color: #4f8bd3;
        text-decoration: none;
        margin-left: 29px;
        text-transform: uppercase;
    }
}

.separator {
    background-color: #f5f5f5;
    height: 1px;
    margin: 7px 0 14px;
}

.bold {
    font-weight: 700;
}

.back-button {
    display: block;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #4f8bd3;
    padding: 12px 20px;
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #f5f5f5;
    background-color: transparent;

    &:before {
        content: '';
        background: url('/assets/images/common/step-details/arrow-back.svg') no-repeat;
        width: 8px;
        height: 13px;
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 8px;
    }
}

.prof-header {
    font-family: $font-heading;
    font-weight: bold;
    letter-spacing: -0.6px;
    text-align: left;
    margin: 0;
    line-height: 1.3;
    color: $color-text-black;
    &--level-1 {
        font-size: 36px;
    }
    &--level-2 {
        font-size: 30px;
    }
    &--level-3 {
        font-size: 25px;
    }
    &--level-4 {
        font-size: 20px;
    }
    &--level-5 {
        font-size: 16px;
    }
}

.step-content {
    .step-type-image {
        height: 60px;
        width: 60px;
    }
}

.show-mobile {
    @media screen and (min-width: $break-point-mobile) {
        display: none !important;
    }
}

.show-tablet {
    @media screen and (min-width: $break-point-tablet) {
        display: none !important;
    }
}

.hide-mobile {
    @media screen and (max-width: $break-point-mobile) {
        display: none !important;
    }
}

.hide-tablet {
    @media screen and (max-width: $break-point-tablet) {
        display: none !important;
    }
}

.org-link {
    letter-spacing: -0.3px;
    text-align: center;
    font-size: 16px;
    color: #4f8bd3 !important;
    text-decoration: underline !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__right {
        margin-left: 10px;
        margin-right: 50px;
    }
    i {
        padding-right: 5px;
        text-decoration: none;
        cursor: pointer;
    }
}

.fa {
    cursor: pointer;
}

.hide {
    display: none;
}

.certification-box-footer-container {
    .certification-box-footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

#floating_sidebar {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.col-sm-1 {
    width: 8.33%;
}
.col-sm-2 {
    width: 16.66%;
}
.col-sm-3 {
    width: 25%;
}
.col-sm-4 {
    width: 33.33%;
}
.col-sm-5 {
    width: 41.66%;
}
.col-sm-6 {
    width: 50%;
}
.col-sm-7 {
    width: 58.33%;
}
.col-sm-8 {
    width: 66.66%;
}
.col-sm-9 {
    width: 75%;
}
.col-sm-10 {
    width: 83.33%;
}
.col-sm-11 {
    width: 91.66%;
}
.col-sm-12 {
    width: 100%;
}

.tooltip-inner {
    background: #222;
    padding: 14px 20px 16px 20px;
    font-family: $font-base;
}

div.screen-lock {
    &.lock {
        opacity: 1;
    }
}

.cursor-default {
    cursor: default !important;
}

.bottom-shadow {
    box-shadow: 0px -20px 30px 20px white;
    position: relative;
}

.recipients-tooltip {
    .tooltip-inner {
        max-width: 100%;
        text-align: left;
        .outer-list-item {
            font-weight: bold;
        }
        .inner-list-item {
            margin-left: 20px;
        }
    }
}

.tooltip {
    &.text-left {
        .tooltip-inner {
            text-align: left;
            white-space: break-spaces;
        }
    }

    &.max-400 {
        .tooltip-inner {
            max-width: 400px;
        }
    }

    &.max-500 {
        .tooltip-inner {
            max-width: 500px;
        }
    }

    &.max-600 {
        .tooltip-inner {
            max-width: 600px;
        }
    }

    &.assignee-tooltip {
        opacity: 1;
        .arrow::before {
            border-top-color: #fff;
        }
        .tooltip-inner {
            padding: 0;
            background-color: #fff;
            min-width: 300px;
        }
    }

    &.min-width-auto {
        .dependency-tooltip {
            min-width: auto !important;
        }
    }
}

.leaflet-custom-circle {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    font-weight: bold;
}

.lg .modal-dialog {
    max-width: 1000px;
}

.xl .modal-dialog {
    max-width: 1400px;
}

body.align_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.color-orange {
    color: $color-orange;
}

#external-link {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 4px;
}

cm-html-content {
    display: block;
    position: relative;
    font-size: 14px;
}

cm-html-content h1 {
    font-size: 22px;
}
cm-html-content h2 {
    font-size: 20px;
}
cm-html-content h3 {
    font-size: 18px;
}
cm-html-content h4 {
    font-size: 16px;
}
cm-html-content h5 {
    font-size: 14px;
}
cm-html-content h6 {
    font-size: 12px;
}

.btn {
    white-space: nowrap !important;
}

.dropdown-toggle {
    white-space: normal;
}
