@import 'variables';
@import '../../style/mixins/action-buttons';
.top-bar {
    .cm-link {
        text-transform: uppercase;
    }
}
.item {
    width: 50%;
    border-right: 1px solid $color-lighter-grey;

    @media screen and (max-width: $break-point-tablet) {
        width: 100%;
        border-right: none;
    }

    &.right {
        .action-content {
            padding: 40px 35px 20px 30px;
        }
    }

    &.left {
        display: flex;
        flex-direction: column;
    }
}

.bottom-bar {
    padding: 10px 30px 6px;
    border-top: 1px solid $color-lighter-grey;
    background: $color-grey-fafa;

    .button-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        @include action-buttons();

        @media screen and (min-width: $break-point-mobile) {
            .btn + .btn {
                margin: 0 0 0 10px;
            }
        }

        @media screen and (max-width: $break-point-mobile) {
            display: flex;
            flex-direction: column;
            width: 100%;

            .btn {
                width: 100%;
                margin: 6px 0 0;

                &:first-child {
                    margin: 0;
                }
            }
        }

        .item.left & {
            justify-content: center;

            @media screen and (min-width: $break-point-mobile) {
                justify-content: flex-end;
            }

            &--accepter {
                justify-content: center;
            }
        }

        &.center {
            justify-content: center;
        }
    }
}
.step-content {
    padding: 23px 30px;
    overflow-y: auto;

    .step-body {
        margin-left: 70px;

        @media screen and (max-width: $break-point-mobile) {
            margin-left: 0;
        }
    }
}

.step-section-title {
    font-size: 12px;
    font-weight: bold;
    color: $color-text-grey;
    padding-bottom: 7px;
    margin: 0 0 13px;
    border-bottom: 1px solid $border-color;
}
