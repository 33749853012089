@import 'variables.scss';

.form-control {
    transition: none;
    font-size: 14px;
    height: 34px;

    &::placeholder {
        color: #aaa;
    }
}

textarea.form-control {
    height: auto;
}

select.form-control:not([size]):not([multiple]) {
    height: 34px;
}

.ch-button {
    padding: 5px 55px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(0deg, #3d78bf, #548fd7);
    border: 1px solid #3d78bf;
    border-radius: 3px;
    outline: none;

    &:hover {
        cursor: pointer;
        background: linear-gradient(0deg, #5a94db, #649de3);
        border: 1px solid #3d78bf;
        color: #ffffff;
    }

    &:focus {
        background: #5088cc;
        border: 1px solid #3d78bf;
        color: #ffffff;
    }

    &.full-width {
        width: 100%;
    }

    &.ch-button-default {
        padding: 0;
        border: none;
        color: #4f8bd3;
        background: transparent;
        font-weight: bold;

        &:hover {
            color: $color-blue-link-hover;
            background: transparent;
            border: none;
            text-decoration: none;
        }
    }

    &.ch-button-rounded {
        border-radius: 20px;
    }

    &-sm {
        padding: 6px 12px;
        font-size: 11px;
        font-weight: bold;
    }

    &.disabled,
    &:disabled {
        opacity: 0.65;
    }
}

.cm-link {
    color: #4f8bd3;
    font-size: 13px;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: $color-blue-link-hover;
        text-decoration: underline;
    }

    &:focus {
        color: #3f7bc4;
        outline: none;
    }

    &.disabled,
    &:disabled {
        color: $color-grey-aaa;
        cursor: not-allowed;

        &:hover,
        &:focus {
            color: $color-grey-aaa;
            text-decoration: none;
        }
    }

    &.cm-link-secondary {
        color: $color-text-primary;

        &:hover {
            color: $color-blue-link-hover;
        }
    }
}

.form-control.email-field-control,
.component-datepicker input.form-control {
    &[disabled] {
        background-color: #e9ecef !important;
        color: #222222;
    }
    &::placeholder {
        font-style: italic;
    }
    &:-ms-input-placeholder {
        font-style: italic;
    }
    &::-ms-input-placeholder {
        font-style: italic;
    }
}

.ch-input {
    padding: 6px 12px;
    height: 34px;
    width: 100%;
    border: 1px solid #dae5e6;
    border-radius: 3px;
    color: #222222;
    font-size: 14px;
    font-weight: 400;
    &.error {
        border: 1px solid $color-text-warning;

        &:focus {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(216, 99, 92, 0.6);
            outline-color: $color-text-warning;
        }
    }
    &[disabled] {
        background-color: #f2f2f2;
        color: #9b9b9b;
    }
}

.ch-select {
    &.error {
        border: 1px solid $color-text-warning;

        &:focus {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(216, 99, 92, 0.6);
            outline-color: $color-text-warning;
        }
    }
}

.ch-radio {
    display: none;

    &-label {
        position: relative;
        padding-left: 28px;
        margin-bottom: 0;
        font-weight: normal;

        &--bold {
            font-weight: bold;
        }

        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $color-white;
            box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
            border: solid 1px #dae5e5;
        }

        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 4px;
            display: none;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $color-blue;
        }

        input:checked + &:after {
            display: block;
        }

        input:disabled + &:hover {
            cursor: not-allowed;
        }

        input:disabled:checked + &:after {
            background-color: #bbbbbb;
        }
    }
}

.ch-checkbox {
    display: none;

    &-label {
        position: relative;
        padding-left: 28px;
        margin-bottom: 0;
        font-weight: normal;

        &--bold {
            font-weight: bold;
        }

        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            font-family: $font-family-awesome;
            background-color: $color-white;
            box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
            border: solid 1px #dae5e5;
        }

        input:checked + &:before {
            color: $color-white;
            font-size: 12px;
            letter-spacing: -0.3px;
            text-align: center;
            background-color: $color-blue;
            border-color: $color-blue;
            box-shadow: none;
            content: '\f00c';
        }

        input:disabled + &:hover {
            cursor: not-allowed;
        }

        input:disabled:checked + &:before {
            background-color: #bbbbbb;
            border-color: #bbbbbb;
        }
    }
}

.ch-datepicker {
    display: block;
    &-error {
        .component-datepicker .form-control {
            border: 1px solid $color-text-warning;
        }
    }
}

.ch-title {
    color: #222222;
    font-size: 30px;
    font-family: $font-heading;
    font-weight: 400;
    margin: 0;
}

.ch-text {
    font-size: 14px;
    color: #777777;
    font-weight: 400;
    margin: 0;
}

.ch-form {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-top: 6px solid #5cd1d8;
    width: 600px;
    padding-top: 50px;
    text-align: center;
    overflow: hidden;
}

.ch-error-text {
    color: $color-text-warning;
    font-size: 14px;
    font-weight: 400;
}

.ch-highlight-text {
    color: #5cd1d8;
}

.ch-timepicker {
    &-error {
        .ngb-tp-minute .form-control,
        .ngb-tp-hour .form-control {
            border: 1px solid $color-text-warning;
        }
    }
}

select.ch-input {
    padding-top: 0;
    padding-bottom: 0;
}

.form-label {
    font-weight: normal;

    &--bold {
        font-weight: bold;
        word-break: break-word;
    }

    &--description {
        color: $color-text-grey;
        margin-bottom: 0.5rem;
        font-weight: normal;
        font-style: italic;
        margin-left: 10px;
    }
}

.required-field:after,
.required:after {
    margin-left: 4px;
    content: '*';
    color: $color-text-warning;
}
