@mixin action-buttons() {
    .ch-button {
        padding: 7px 32px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.3px;
    }
    .cm-link {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.3px;
        text-align: center;
        color: #4f8bd3;
        text-decoration: none;
        margin-left: 29px;
        text-transform: uppercase;
    }
}
